import React from 'react'
import logo from './assets/img/logo512.png'
import './assets/css/App.css'

function App() {
    return (
        <div className='App'>
            <header className='App-header'>
                <img src={logo} className='App-logo' alt='logo' />
                SMILES! NOW!
            </header>
        </div>
    )
}

export default App
